<template>
  <div>
    <div class="inline-flex items-center gap-1">
      <span>
        <b>{{ label }}:</b> &euro;&nbsp;{{ formatPrice(value ? value : (heen || 0) + (isRetour ? retour || 0 : 0)) }}
      </span>
      <span v-if="shouldShowSlot" class="italic text-sm text-gray-500 opacity-90">
        <slot></slot>
      </span>
    </div>
    <div v-if="isRetour && (heen > 0 || retour > 0)" class="-mt-0.5 mb-0.5 flex flex-col gap-0 items-start pl-4 italic text-xs text-gray-600 opacity-90">
      <span><b>Heenreis:</b> &euro;&nbsp;{{ formatPrice(heen) }}</span>
      <span><b>Retour:</b> &euro;&nbsp;{{ formatPrice(retour) }}</span>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, useSlots } from 'vue'

import { formatPrice } from '@/functions/formatNumber'

defineProps({
  isRetour: Boolean,
  value: Number,
  heen: Number,
  retour: Number,
  label: String,
})

const slots = useSlots()
const shouldShowSlot = computed(() => !!slots.default)
</script>

<template>
  <div>
    <UITableResponsive :items="items" :headers="['Gebruiker', 'Feedback', 'Status']">
      <template #colgroup>
        <colgroup>
          <col class="w-8">
          <col class="w-80">
          <col align="center" class="text-center">
        </colgroup>
      </template>
      <template #item-Gebruiker="{ item }">
        <div class="flex flex-col">
          <span>{{ store.getters.chauffeur(item.user_id)?.label }}</span>
          <span class="italic text-gray-500">{{ dateTimeStringLocale(item.creation_date) }}</span>
        </div>
      </template>
      <template #item-Feedback="{ item }">
        <div class="block whitespace-normal">
          {{ item.feedback }}
        </div>
      </template>
      <template #item-Status="{ item }">
        <span class="btn" :class="{ error: !item.is_behandeld, success: item.is_behandeld }">
          {{ item.is_behandeld ? 'Behandeld' : 'Niet behandeld' }}
        </span>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'

import UITableResponsive from '@/components/UI/Table/Responsive'

import { dateTimeStringLocale } from '@/functions/formatDate'

defineProps({
  items: Array,
})
const store = useStore()
</script>
